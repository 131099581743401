import { Container } from "./styles";
import ReactInputMask from "react-input-mask";
import type { InputHTMLAttributes } from "react";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  mask?: string;
  error?: string | null;
  inputStyle?: React.CSSProperties;
  showAsterisk?: boolean;
}

export function TextInput({
  name,
  label,
  mask,
  error,
  showAsterisk,
  inputStyle,
  style,
  ...rest
}: TextInputProps) {
  return (
    <Container style={style} isError={!!error}>
      {label && (
        <label className="label" htmlFor={name}>
          {showAsterisk && <span className="asterisk">*</span>} {label}
        </label>
      )}

      {mask ? (
        <ReactInputMask
          mask={mask}
          id={name}
          name={name}
          style={inputStyle}
          {...rest}
        />
      ) : (
        <input id={name} name={name} style={inputStyle} {...rest} />
      )}

      {error && <p className="error">{error}</p>}
    </Container>
  );
}
